<template>
  <v-btn @click="reset" block>
    {{ data.title }}
    <v-icon
      right
      v-if="data.icon"
    >
    {{ data.icon }}
    </v-icon>
  </v-btn>
</template>
<script>
export default {
  props: ["data"],
  methods: {
    reset() {
      this.$store.commit("map/setResetMap", true);
      this.$store.commit("map/setResetJourney", true);
      this.$store.commit("map/setResetLocation", true);
      this.$store.commit("map/setSelectedLocation", null);
      this.$store.commit("map/setHotspotGroupSelectedLocation", null);
      this.$store.commit("map/setJourney", {
        startLocation: null,
        stopLocation: null,
        entrance: null,
        exit: null,
      });
    },
  },
};
</script>
<style></style>
