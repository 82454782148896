<template>
  <div>
    <div tabindex="-1" v-if="isMounted">
      <!-- <slot /> -->
    </div>
    <div class="map-html-overlays">
      <slot />
    </div>
  </div>
</template>

<script>
import { loadModules } from "esri-loader";
import { Camera } from "@egjs/view360";
// import { extractFilenameFromHeader } from "../../../dist/pdfjs/build/pdf";
// import ApiService from "../services/api.service"

export default {
  name: "MapArc",
  components: {},
  data: () => ({
    isMounted: false,
    handler: {
      doubleClick: null,
      mouseWheel: null,
      dragStart: null,
      drag: null,
      keyDown: null,
    },
  }),
  props: [
    "selectedLayers",
    "mapZoom",
    "cameraTilt",
    "heading",
    "mapMinZoom",
    "mapMaxZoom",
    "mapCenter",
    "bottomLeftExtent",
    "topRightExtent",
    "portalItemId",
    "portalItemType",
    "filterJson",
    "layerOptions",
    "tokens",
    "basemapWidget",
    "showLegend",
  ],
  provide() {
    const self = this;
    return {
      get map() {
        return self.map;
      },
      get markerLayer() {
        return self.markerLayer;
      },
      get view() {
        return self.view;
      },
    };
  },

  created() {
    console.log("MapCreated");
    this.maps = [];
  },

  mounted() {
    console.log("MapMounted");
    // console.log( "ultima", this.bottomLeftExtent,
    // this.topRightExtent, Number(this.topRightExtent.x))
    this.view = undefined;
    this.map = undefined;
    this.markerLayer = undefined;
    // this.tokens = this.$store.state.map.esriTokens;

    // Get ArcGIS Tokens
    // ApiService.getEsriToken().then((response) => {

    // this.$store.state.map.tokens = response.data

    // lazy load the required ArcGIS API for JavaScript modules and CSS
    loadModules(
      [
        "esri/WebMap",
        "esri/views/MapView",
        "esri/WebScene",
        "esri/views/SceneView",
        "esri/identity/IdentityManager",
        "esri/widgets/Search",
        "esri/widgets/Expand",
        "esri/widgets/Legend",
        "esri/widgets/BasemapGallery",
        "esri/core/watchUtils",
        "esri/renderers/visualVariables/RotationVariable",
      ],
      {
        css: true,
      }
    ).then(
      ([
        WebMap,
        MapView,
        WebScene,
        SceneView,
        IdentityManager,
        Search,
        Expand,
        Legend,
        BasemapGallery,
        watchUtils,
        RotationVariable,
      ]) => {
        let self = this;

        console.log(this.mapZoom);
        // Firstly any tokens for ArcGIS
        this.tokens.forEach(function (token) {
          console.log("TOKEN!!!");
          console.log({
            token: token.token,
            server: token.server_url,
          });
          IdentityManager.registerToken({
            token: token.token,
            server: token.server_url,
          });
        });

        console.log("portalItemId: " + this.portalItemId);
        console.log("portalItemType: " + this.portalItemType);

        if (this.portalItemType == "webscene") {
          if (this.portalItemId !== null && this.portalItemId !== undefined) {
            this.map = new WebScene({
              portalItem: {
                id: this.portalItemId,
              },
            });
          } else {
            this.map = new WebScene({
              basemap: "topo",
              ground: "world-elevation",
            });
          }
          console.log("sassadasd");
          // Create the scene view
          this.view = new SceneView({
            container: this.$el,
            map: this.map,
            zoom: this.mapZoom,
            center: this.mapCenter,
            // extent

            // tilt: 100 || 0,
            // camera: {
            //   tilt: 360 || 0
            // }
          });
          this.view.constraints = {
            minZoom: this.mapMinZoom,
            rotationEnabled: this.isRotationEnabled,
            maxZoom: this.mapMaxZoom,
          };
        } //Just create a regular map if webscene isn't specified
        else {
          if (this.portalItemId !== null && this.portalItemId !== undefined) {
            this.map = new WebMap({
              portalItem: {
                id: this.portalItemId,
              },
            });
          } else {
            this.map = new WebMap({
              basemap: "topo",
            });
          }

          // Create the scene view
          if (this.bottomLeftExtent !== null || this.topRightExtent !== null) {
            this.view = new MapView({
              container: this.$el,
              map: this.map,
              zoom: this.mapZoom,
              center: this.mapCenter,
            });
            this.view.constraints = {
              geometry: {
                type: "extent",
                xmin: Number(this.bottomLeftExtent.x),
                ymin: Number(this.bottomLeftExtent.y),
                xmax: Number(this.topRightExtent.x),
                ymax: Number(this.topRightExtent.y),
              },
              minZoom: this.mapMinZoom,
              maxZoom: this.mapMaxZoom,
              rotationEnabled: this.isRotationEnabled,
            };
          } else {
            this.view = new MapView({
              container: this.$el,
              map: this.map,
              zoom: this.mapZoom,
              center: this.mapCenter,
            });
            this.view.constraints = {
              minZoom: this.mapMinZoom,
              maxZoom: this.mapMaxZoom,
              rotationEnabled: this.isRotationEnabled,
            };
          }
        }

        this.view.ui.move(
          ["zoom", "navigation-toggle", "compass"],
          "top-right"
        );
        // this.view.popup.autoOpenEnabled = false;
        this.view.popup.autoCloseEnabled = true;
        this.view.popup.dockEnabled = false;
        this.view.popup.actions = [];
        this.view.popup.dockOptions = { buttonEnabled: false };

        // Change the cursor to pointer if we're hovering a marker
        this.view.on("pointer-move", function (evt) {
          var screenPoint = {
            x: evt.x,
            y: evt.y,
          };
          self.view.hitTest(screenPoint).then(function (response) {
            self.$el.style.cursor = "default";
            if (response.results.length > 0)
              if (response.results[0].graphic.layer === self.markerLayer)
                self.$el.style.cursor = "pointer";
          });
        });

        this.view.on("click", function (evt) {
          var screenPoint = {
            x: evt.x,
            y: evt.y,
          };

          console.log(evt.mapPoint.longitude);
          console.log(evt.mapPoint.latitude);

          self.view.hitTest(screenPoint).then(function (response) {
            if (
              response.results.length === 0 &&
              self.$store.state.mapTool === "comment"
            ) {
              self.$store.dispatch("map/placeComment", [
                evt.mapPoint.longitude,
                evt.mapPoint.latitude,
              ]);
            }
          });
        });

        // Fire an event when the map changes it's focus
        watchUtils.watch(self.view, "extent", function () {
          self.$emit("mapMoved", self.view.extent);
          self.$emit("mapZoomed", self.view.zoom);
        });

        //Add the default search widget
        const search = new Search({
          view: this.view,
        });
        this.view.ui.add(search, "top-left");

        // Add the legend widget

        const legend = new Expand({
          content: new Legend({
            view: this.view,
          }),
          view: this.view,
          expanded: this.showLegend,
        });
        this.view.ui.add(legend, "bottom-left");

        // Add the basemap widget
        const basemapGallery = new Expand({
          content: new BasemapGallery({
            view: this.view,
          }),
          view: this.view,
          expanded: false,
        });

        if (this.basemapWidget) {
          this.view.ui.add(basemapGallery, "bottom-right");
        }

        this.isMounted = true;
        self.$emit("mapLoadEnd");
        this.$emit("ready");
      }
    );
    // });
  },
  methods: {
    zoomToCenter: function (center, zoom, tilt) {
      //Reset Camera Tilt
      // this.cameraTilt = 0;
      this.$emit("resetTilt", 0);
      console.log("SET LOCATION GO TO", center, zoom, tilt, this.cameraTilt);
      this.view
        .goTo({
          center: center,
          zoom: zoom,
          tilt,
          heading: 0,
        })
        .catch(function (error) {
          if (error.name != "AbortError") {
            console.error(error);
          }
        });
    },
    zoomToPoints: function (pointArray) {
      if (pointArray.length === 0) return;

      let self = this;
      loadModules(["esri/geometry/Multipoint"], {
        css: true,
      }).then(([Multipoint]) => {
        let multiPoint = new Multipoint({ points: pointArray });
        self.view.goTo(multiPoint).catch(function (error) {
          if (error.name != "AbortError") {
            console.error(error);
          }
        });
      });
    },
    zoomToLocation: function (markerZoom, center) {
      let options = {
        duration: 2000,
        easing: "linear",
        // camera: {
        //   tilt: this.cameraTilt || 100
        // }
      };

      console.log("Current View", this.view.zoom);
      console.log("Target View", markerZoom);
      console.log("SET LOCATION GO TO2", center, markerZoom, this.cameraTilt);
      this.view.goTo(
        {
          target: center,
          heading: this.heading,
          zoom: markerZoom,
          tilt: this.cameraTilt,
          // camera: {
          //   tilt: this.cameraTilt || 300
          // }
        },
        options
      );
      console.log("New View", this.view.zoom);
      if (this.selectedLocation) {
        // this.view.navigation.mouseWheelEnable = false
        this.handler.mouseWheel = this.view.on("mouse-wheel", function (event) {
          // prevents panning with the mouse drag event
          event.stopPropagation();
        });
        let initialX = null;
        let initialHeading = null;

        this.handler.dragStart = this.view.on("pointer-down", (event) => {
          initialX = event.x;
          initialHeading = this.view.camera.heading;
          console.log("ORBIT 2", initialX, initialHeading);

          event.stopPropagation();
        });

        this.handler.drag = this.view.on("drag", (event) => {
          if (initialX !== null && initialHeading !== null) {
            const deltaX = event.x - initialX;
            let newHeading = initialHeading - deltaX * 0.2;

            // Normalize the heading to be between 0 and 360
            newHeading = (newHeading + 360) % 360;

            this.view.camera.heading = newHeading;
            this.view
              .goTo({
                heading: newHeading,
              })
              .catch(function (error) {
                if (error.name != "AbortError") {
                  console.error(error);
                }
              });

            console.log("ORBIT DS", newHeading);
          }
          event.stopPropagation();
        });

        this.handler.dragEnd = this.view.on("drag-end", function (event) {
          initialX = null;
          initialHeading = null;
        });

        this.handler.doubleClick = this.view.on(
          "double-click",
          function (event) {
            // prevents panning with the mouse drag event
            event.stopPropagation();
          }
        );
        this.handler.keyDown = this.view.on("key-down", function (event) {
          const keyPressed = event.key;
          if (keyPressed.slice(0, 5) === "Arrow") {
            event.stopPropagation();
          }
        });
        this.view.ui.remove("zoom");
        this.view.ui.move(["navigation-toggle", "compass"], "top-right");
        // this.view.popup.autoOpenEnabled = false;
        this.view.popup.autoCloseEnabled = true;
        this.view.popup.dockEnabled = false;
        this.view.popup.actions = [];
        this.view.popup.dockOptions = { buttonEnabled: false };
      }
    },
    applyFilters: function () {
      console.log("Updating layer filters");
      let self = this;
      self.view.layerViews.forEach(function (layerView) {
        layerView.filter = self.filterJson;
      });
    },
    applyOptions: function () {
      console.log("Updating layer options");
      let self = this;
      self.view.map.layers.forEach(function (layer) {
        if (layer.title in self.layerVisibility) {
          layer.visible = self.layerVisibility[layer.title];
        }
      });
    },
  },
  computed: {
    resetMap() {
      return this.$store.state.map.resetMap;
    },
    hotspotGroupSelectedLocation() {
      return this.$store.state.map.hotspotGroupSelectedLocation;
    },
    selectedLocation() {
      return this.$store.state.map.selectedLocation;
    },
    layerVisibility() {
      let layers = {};
      let layerOptions = this.layerOptions;

      //Build a list of layers and their visibility status
      for (let group in layerOptions) {
        for (let layer in layerOptions[group]) {
          let visible = layerOptions[group][layer];
          layers[layer] = visible;
        }
      }
      return layers;
    },
  },
  watch: {
    resetMap: function (value) {
      if (value) {
        const center = [Number(this.mapCenter[0]), Number(this.mapCenter[1])];
        this.zoomToCenter(center, this.mapZoom, 0);
        this.$store.commit("map/setResetMap", false);
      }
    },
    hotspotGroupSelectedLocation: {
      async handler(value) {
        const center = [Number(value.location.lng), Number(value.location.lat)];
        this.zoomToLocation(18, center);
      },
      deep: true,
    },
    selectedLocation: {
      async handler(value) {
        if (!value) {
          this.handler.mouseWheel.remove();
          this.handler.dragStart.remove();
          this.handler.drag.remove();
          this.handler.keyDown.remove();
          this.handler.doubleClick.remove();

          this.view.ui.add("zoom");
          this.view.ui.move(
            ["zoom", "navigation-toggle", "compass"],
            "top-right"
          );
          // this.view.popup.autoOpenEnabled = false;
          this.view.popup.autoCloseEnabled = true;
          this.view.popup.dockEnabled = false;
          this.view.popup.actions = [];
          this.view.popup.dockOptions = { buttonEnabled: false };
        }
        // else {
        //   let options = {
        //     duration: 2000,
        //     easing: "linear",
        //     // camera: {
        //     //   tilt: this.cameraTilt || 100
        //     // }
        //   };

        //   this.view.goTo(
        //     {
        //       // target: center,
        //       // zoom: markerZoom,
        //       tilt:0
        //       // camera: {
        //       //   tilt: this.cameraTilt || 300
        //       // }
        //     },
        //     options
        //   );
        // }
      },
      deep: true,
    },
    filterJson: function () {
      this.applyFilters();
    },
    layerOptions: function () {},
    layerVisibility: function () {
      console.log("layer visiblity.....");
      this.applyOptions();

      // loadModules(["esri/layers/FeatureLayer", "esri/layers/TileLayer", "esri/layers/ImageryLayer"], {
      //   css: true,
      // }).then(([FeatureLayer, TileLayer]) => {
      //   view.map.layers.add(new TileLayer({
      //     url: self.url,
      //     id: self.name,
      //     opacity: self.opacity,
      //     visible: self.visible,
      //   }));
      // })
    },
  },
};
</script>

<style scoped>
div {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
}

.marker {
  position: absolute;
  /* top: 0px; */
  /* left: 0px; */
}

.centered-block {
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  position: absolute;
}

.esri-view-width-xsmall .esri-expand--auto .esri-expand__container--expanded {
  position: relative !important;
}

.esri-popup__main-container {
  max-width: 300px;
}

.esri-view-height-less-than-medium .esri-popup__main-container {
  max-height: 200px;
}
.esri-view-width-xlarge .esri-popup__main-container {
  width: 140px;
}

.esri-view-width-medium .esri-popup__main-container {
  width: 140px;
}

.map-html-overlays {
  position: absolute;
  overflow: hidden;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  pointer-events: None;
}

>>> .esri-ui {
  z-index: 2;
}

>>> .esri-view-width-xsmall
  .esri-expand--auto
  .esri-expand__container--expanded {
  position: relative;
}
</style>
