<template>
  <div
    style="overflow: hidden"
    @keydown.up.exact.prevent="nextMarker()"
    @keydown.down.exact.prevent="prevMarker()"
  >
    <MapArc
      v-if="pageData !== undefined"
      ref="map"
      :showLegend="pageData.show_legend"
      :basemapWidget="pageData.basemap_widget"
      :mapZoom="pageData.zoom"
      :cameraTilt="cameraTilt"
      :heading="heading"
      :mapMinZoom="pageData.minZoom"
      :mapMaxZoom="pageData.maxZoom"
      :mapCenter="[pageData.lng, pageData.lat]"
      :bottomLeftExtent="pageData.bottomLeftpoint"
      :topRightExtent="pageData.topRightpoint"
      :isRotationEnabled="pageData.disableMapRotation"
      :portalItemId="portalItemId"
      :portalItemType="portalItemType"
      :filterJson="$store.state.map.layerFilter"
      :layerOptions="$store.state.wagtail.layerOptions"
      :tokens="pageData.tokens"
      :selectedLayers="selectedLayers"
      @ready="mapReady()"
      @mapLoadStart="loading = true"
      @mapLoadEnd="loading = false"
      @mapMoved="mapMoved"
      @mapZoomed="mapZoomed"
      @resetTilt="cameraTilt = $event"
      tabindex="-1"
    >
      <div
        v-for="(item, i) in pageData.children"
        :key="pageData.id + 'page_' + i"
        @click="markerClicked(item)"
      >
        <!-- Create a HTML marker for each marker - note this needs to site alongside the map, and be passed the map component as a prop -->
        <MapMarkerArcHtml
          v-show="
            (markerVisible || item.type !== 'mapzoomtolocation') &&
            (item.marker_zoom <= currZoomLevel || item.marker_zoom == 1)
          "
          v-if="
            (item.type == 'mappage' && item.showMarker) ||
            (item.type !== 'mappage' && item.lat !== null && item.lng !== null)
          "
          :id="i"
          :coordinates="[item.lng, item.lat]"
          :colour="$vuetify.theme.themes.light.secondary"
          :title="item.title"
          :icon="item.menu_icon"
          :mapCenter="mapCenter"
          :mapArc="$refs.map"
          :arialabel="getAriaLabel(item)"
          :currZoomLevel="currZoomLevel"
          :markerType="item.type"
          :layerData="{ lat: item.lat, lng: item.lng }"
          :hotspotGroupData="item.hotspotGroup"          
          @keydown.up.exact.prevent="nextMarker()"
          @keydown.down.exact.prevent="prevMarker()"
        />
      </div>
    </MapArc>

    <!-- <Searchbar class="searchbar" /> -->
    <Loading v-show="loading" class="loading" />
    <LightboxGallery
      :showLightbox="lightboxContent.length > 0 && lightboxReady"
      :content="lightboxContent"
      @closed="closeLightbox()"
    />
    <slot />
  </div>
</template>

<script>
import MapArc from "./MapArc.vue";
import MapMarkerArcHtml from "./MapMarkerArcHtml.vue";
import Loading from "./Loading.vue";
import LightboxGallery from "../lightbox/LightboxGallery.vue";

export default {
  name: "Map",
  props: {
    pageData: Object,
    accessibilityZoom: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    selectedLayers: [],
    accessToken:
      "pk.eyJ1IjoiZ2hkLWlkcyIsImEiOiJja2ZveHVycHkwNXlrMnpydmh0dWhmaXV5In0.KK_IxnZ4H4-9eEOIaUjN8g",
    mapStyle: "mapbox://styles/ghd-ids/ckg1rthma03821amhn8m49xlo",
    mapCenter: [152.849434, -25.301246],
    popupCoordinates: [0, 0],
    // mapZoom: 15.51,
    mapMinZoom: 7,
    mapMaxZoom: 16,
    cameraTilt: 0,
    heading: 0,
    showPopup: false,
    popupText: "",
    loading: true,
    lightboxData: undefined,
    lightboxReady: false,
    accessibilityModal: false,
    counter_flag: 0,
    previousFocusItem: undefined,
    markerZoom: 0,
    markerVisible: true,
    zoomMarkerFlag: true,
    currZoomLevel: 0,
    transformedObject: {},    
  }),
  components: {
    MapArc,
    MapMarkerArcHtml,
    Loading,
    LightboxGallery,
  },
  mounted() {
    if (this.pageData && Array.isArray(this.pageData.children)) {
      this.pageData.children.forEach((child, index) => {
        console.log(`HSG Child at index ${index}:`, child);
        if (child.hotspotGroup != null) {
          console.log("HSG!", child.hotspotGroup);
          if (child.hotspotGroup.visible_by_default) {
            const currentVisibileMarkerGroups = []
            currentVisibileMarkerGroups.push(child.hotspotGroup.id);
            this.$store.commit(
              "wagtail/setVisibleHotspotGroups",
              currentVisibileMarkerGroups
            );
          }
        }
      });
    }

    if (this.pageData.show_legend_header == false) {
      const header_style = `
      h3.esri-widget__heading.esri-legend__service-label {
       display: none !important;
      }

      .esri-legend__service {
          padding-top: 4px !important;
          padding-bottom: 0px !important;
          border-bottom: none !important;
      }`;
      const styleSheet = document.createElement("style");
      styleSheet.innerText = header_style;
      document.head.appendChild(styleSheet);
    }
  },
  created() {},
  computed: {
    selectedLocation() {
      return this.$store.state.map.selectedLocation;
    },
    pageUpdated() {
      return this.pageData;
    },
    markerUpdated() {
      return this.$store.state.currentMarker;
    },
    lightboxContent() {
      if (this.$store.state.wagtail.currentPopup === undefined) {
        return [];
      } else if (this.$store.state.wagtail.currentPopup.question) {
        return this.$store.state.wagtail.currentPopup.question;
      } else return this.$store.state.wagtail.currentPopup.content;
    },
    portalItemId() {
      if (
        this.pageData.esri_map !== undefined &&
        this.pageData.esri_map !== null
      )
        return this.pageData.esri_map.portal_item_id;
      return null;
    },
    portalItemType() {
      if (
        this.pageData.esri_map !== undefined &&
        this.pageData.esri_map !== null
      )
        return this.pageData.esri_map.portal_item_type;
      return null;
    },
    islightboxActive() {
      if (this.$store.state.lightbox.lightboxObjects.length == 0) {
        return false;
      } else {
        return true;
      }
    },
    zoomTo() {
      if (this.pageData.zoom !== undefined && this.pageData.zoom !== null)
        return this.pageData.zoom;
      return null;
    },

    accessibilityMarkerZoom() {
      return this.$store.state.map.accessibilityMarkerZoom;
    },
  },
  watch: {
    selectedLocation: {
      async handler(data) {
        if (data) {
          this.markerZoom = data.zoomLevel;
          this.center = [Number(data.location.lng), Number(data.location.lat)];
          this.cameraTilt = data.tilt_offset;
          this.heading = data.heading;
          await this.$nextTick();
          this.$refs.map.zoomToLocation(this.markerZoom, this.center, {});
          this.zoomMarkerFlag = true;
        } else {
          console.log("no data");
          this.cameraTilt = 0;
          this.heading = 0;
          const center = [Number(this.pageData.lng), Number(this.pageData.lat)];
          this.$refs.map.zoomToCenter(center, this.pageData.zoom, 0); // this.previousFocusItem = document.activeElement;
          // this.markerZoom = this.accessibilityMarkerZoom.zoomLevel;
          // this.center = [
          //   Number(this.accessibilityMarkerZoom.lng),
          //   Number(this.accessibilityMarkerZoom.lat),
          // ];
          // this.$refs.map.zoomToLocation(this.markerZoom, this.center);
          // this.zoomMarkerFlag = true;
        }
      },
      deep: true,
    },
    pageUpdated() {
      this.pageSelected();
    },
    markerUpdated() {
      this.markerSelected();
    },
    markerZoom(newValue) {
      console.log(newValue, "new value of zoom");
    },
    islightboxActive() {
      //return this.$store.state.lightbox.lightboxObjects.length
      if (this.islightboxActive == false) {
        if (localStorage.getItem("accessibilityModeState") != "true") {
          //return focus to the previously focssed item after lightbox closes
          this.$nextTick(() => {
            if (this.previousFocusItem != undefined) {
              this.previousFocusItem.focus();
            }
          });
        }
      }
    },
  },
  methods: {
    mapReady: function () {
      if (this.accessibilityZoom) {
        setTimeout(() => {
          this.previousFocusItem = document.activeElement;
          this.markerZoom = this.accessibilityMarkerZoom.zoomLevel;
          this.center = [
            Number(this.accessibilityMarkerZoom.lng),
            Number(this.accessibilityMarkerZoom.lat),
          ];
          this.$refs.map.zoomToLocation(this.markerZoom, this.center);
          this.zoomMarkerFlag = true;
        }, 2000);
      } else {
        let self = this;
        setTimeout(function () {
          if (!this.accessibilityZoom) {
            self.zoomToMarkers();
          }
        }, 2000);
      }
    },
    pageSelected: function () {
      this.zoomToMarkers();
    },
    markerClicked: function (markerData) {
      this.previousFocusItem = document.activeElement;
      console.log(markerData, "WagtailService.getPageById");

      if (markerData.type === "mappage") {
        this.$router.push(new URL(markerData.html_url).pathname);
      }
      if (markerData.type === "redirecturl") {
        window.location.href = markerData.target;
      }
      if (markerData.type == "mapzoomtolocation") {
        this.markerZoom = markerData.zoom;
        console.log("MZ MD", markerData);

        this.center = [Number(markerData.lng), Number(markerData.lat)];
        this.$refs.map.zoomToLocation(this.markerZoom, this.center);
        this.zoomMarkerFlag = true;

        this.$store.dispatch("map/getPopup", markerData.id);

        //Get current popup (clicked) then get all necessary value to create an object that can be passed in the layerOptions
        console.log("MZ pre");
        //const chosenLayer = this.$store.state.map.currentPopup.map_layers[0].value;
        const chosenLayer = markerData.map_layers;
        // this.selectedLayers = chosenLayer

        console.log("MZ layername", chosenLayer);

        this.transformedObject = {
          key: this._uid,
          data: {
            [chosenLayer]: true,
          },
        };

        console.log(this.transformedObject, "payloadMap");

        this.$store.commit("wagtail/setLayerOptions", this.transformedObject);
      }
      if (markerData.type.includes("popup")) {
        this.$store.dispatch("map/getPopup", markerData.id);
        let id = markerData.id;
        this.lightboxReady = true;
        //TODO: I don't like using the store for this - should call the API service directly
        this.$store.dispatch("wagtail/getPopup", id);
        return (this.dialog.value = true);
      }
    },
    markerSelected: function () {
      //If we have a current marker zoom to it, otherwise zoom to all markers
      if (this.$store.state.currentMarker !== undefined) {
        var center = [
          Number(this.$store.state.currentMarker.long),
          Number(this.$store.state.currentMarker.lat),
        ];
        this.$refs.map.zoomToCenter(
          center,
          this.$store.state.currentMarker.zoom
        );
      } else {
        this.zoomToMarkers();
      }
    },
    zoomToMarkers: function (resetTilt) {
      if (this.pageData !== undefined) {
        //Assemble an array of point long/lats and pass them through to zoom onto
        const pointArray = [];
        this.pageData.children.forEach((element) => {
          if (
            element.icon !== null &&
            element.lng !== null &&
            element.lat !== null
          )
            pointArray.push([Number(element.lng), Number(element.lat)]);
        });

        if (pointArray.length === 0) {
          var center = [Number(this.pageData.lng), Number(this.pageData.lat)];
          console.log("back");
          this.$refs.map.zoomToCenter(center, this.pageData.zoom, 0);
        } else {
          this.$refs.map.zoomToPoints(pointArray);
        }
      }
    },
    closeLightbox() {
      //TODO: Make map popups update routes
      // this.$router.push(new URL(this.pageData.meta.html_url).pathname);
      this.$store.commit("wagtail/getPopupSuccess", undefined);

      this.lightboxReady = false;
    },
    mapMoved(evt) {
      if (this.accessibilityMarkerZoom) {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.$emit("mapMoved");
        }, 500);
      }
      this.mapCenter = [evt.center.latitude, evt.center.longitude];
    },
    mapZoomed(evt) {
      this.currZoomLevel = evt;
      if (this.currZoomLevel >= this.pageData.zoom * 1.1) {
        // console.log("test", evt , "vs" , this.pageData.zoom,"and", this.pageData.children[0].type)
        this.markerVisible = false;
      } else this.markerVisible = true;
    },
    getAriaLabel(item) {
      if (item.accessible_label) {
        return item.accessible_label;
      } else {
        return item.title;
      }
    },
  },
};
</script>

<style scoped>
.searchbar {
  position: absolute;
  top: 12px;
  left: 270px;
}

.loading {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}

.map-legend {
  position: absolute;
  bottom: 35px;
  left: 15px;
  width: 344px;
}

.esri-view-width-xsmall .esri-expand--auto .esri-expand__container--expanded {
  position: relative !important;
}

@media (max-width: 600px) {
  .searchbar {
    width: unset;
    right: 10px;
  }

  .map-legend {
    width: unset;
    right: 15px;
  }
}

>>> .esri-view-width-xsmall
  .esri-expand--auto
  .esri-expand__container--expanded {
  position: relative;
}
</style>
